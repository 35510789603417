import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AppSettings } from "src/assets/config/app-settings";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: 'root',
})
export class SseService {
  private eventSource: EventSource | undefined;
  private url: string | undefined;
  private events$!: Observable<any>;
  private retryCount = 0;
  private maxRetries = 3;

  constructor(
    private settings: AppSettings,
    private session: SessionService
  ) {
    this.initializeEventSource();
  }

  private initializeEventSource() {
    if (this.retryCount > this.maxRetries) {
      console.error("Max retries reached");
      return;
    }

    // Close any existing EventSource
    if (this.eventSource) {
      this.eventSource.close();
    }

    const userId = this.session.user?.userId!;
    const customerId = this.session.user?.customer?._id!;
    this.url = `${this.settings.apiBaseUrl}/api/events?userId=${userId}&customerId=${customerId}`;
    this.eventSource = new EventSource(this.url!);
    
    this.events$ = new Observable(observer => {
      this.eventSource!.onmessage = event => {
        this.retryCount = 0; // Reset on successful message
        observer.next(JSON.parse(event.data));
      };

      this.eventSource!.onerror = error => {
        console.error("Error occurred", error);
        this.retryCount++;
        setTimeout(() => {
          this.initializeEventSource();
        }, Math.pow(2, this.retryCount) * 1000); // Exponential backoff delay
      };

      return () => {
        this.eventSource!.close();
      };
    });
  }

  getServerSentEvent(): Observable<any> {
    return this.events$;
  }
}
